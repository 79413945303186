import { useNavigate } from 'react-router-dom';
import PageHeader from '../job_tracker/PageHeader';
import { GoogleLoginComponent, LoginForm } from './Login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Button } from '../components/ui/button';
import { ToastContainer } from 'react-toastify';

const Register = () => {
  const navigate = useNavigate();

  return (
    <GoogleOAuthProvider clientId="132628962558-93gijml44r2ji6670u0umkbbta83cher.apps.googleusercontent.com">
      <PageHeader/>
      <div className="flex flex-col border rounded-md max-w-lg mx-auto mt-16 p-8 gap-4">
        <LoginForm endpoint="/register/" errorMessage="Failed to register" buttonText="Sign Up"/>
        <div className="flex flex-col items-center gap-4 mt-4">
          <GoogleLoginComponent
              endpoint="/google_oauth_register/" 
              errorMessage="Failed to register"
              buttonText="Register with Google"
          />
          <div className="flex flex-row items-center gap-4">
              <p>
                Already have an account?
              </p>
              <Button variant="ghost" className="border-2" onClick={() => navigate('/login')}>Log In</Button>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" hideProgressBar/>
    </GoogleOAuthProvider>
  );
}

export default Register;