import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth, DOMAIN, is_already_authenticated } from '../api';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../job_tracker/PageHeader';

import { Button } from '../components/ui/button';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "../components/ui/form"
  import { Input } from "../components/ui/input"
import { toast, ToastContainer } from 'react-toastify';
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
const formSchema = z.object({
    email: z.string().min(1),
    password: z.string().min(8, { message: 'Password must be at least 8 characters long' }),
})

export function GoogleLoginComponent({endpoint, errorMessage, buttonText}) {
    const navigate = useNavigate();
    const onSuccess = (response) => {
        const token = response.credential;
        auth.post(endpoint, {token}).then(res => {
          const token = res.data?.token;
          localStorage.setItem('token', token);
          console.log(res);
          navigate('/app'); 
        }).catch(err => {
          console.log(err);
          const error_msg = err?.response?.data?.error || errorMessage;
          toast.error(error_msg);
        });
      };
    
      const onFailure = (response) => {
        console.log(response);
        toast.error(errorMessage);
      };
    return (
        <GoogleLogin
          redirectUri={new URL('app/', DOMAIN).href}
          onSuccess={onSuccess}
          onFailure={onFailure}
          buttonText={buttonText}
        />
    )
}


export function LoginForm({endpoint, errorMessage, buttonText}) {
    const form = useForm({
        resolver: zodResolver(formSchema),
    })
    const navigate = useNavigate();
    const onSubmit = (values) => {
        auth.post(endpoint, values)
        .then(res => {
          const token = res.data?.token;
          localStorage.setItem('token', token);
          navigate('/app');
        })
        .catch(err => {
          console.log(err);
          const error_msg = err?.response?.data?.error || errorMessage;
          toast.error(error_msg);
        });
    }
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <div className="flex flex-col gap-4">
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input placeholder="Email" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Password</FormLabel>
                            <FormControl>
                                <Input placeholder="Password" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <Button className="w-full" variant="secondary" type="submit">{buttonText}</Button>
            </div>
            </form>
        </Form>
    )
}



const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            is_already_authenticated().then(authenticated => {
                if(authenticated) {
                    navigate('/app');
                }
            })
        }
        checkAuth();
    }, [])

    return (
        <GoogleOAuthProvider clientId="132628962558-93gijml44r2ji6670u0umkbbta83cher.apps.googleusercontent.com">
            <PageHeader/>
            <div className="flex flex-col border rounded-md max-w-lg mx-auto mt-16 p-8 gap-4">
                <LoginForm endpoint="/login/" errorMessage="Incorrect login credentials" buttonText="Log In"/>
                <div className="flex flex-col items-center gap-4 mt-4">
                    <GoogleLoginComponent 
                        endpoint="/google_oauth_login/" 
                        errorMessage="Incorrect login credentials"
                        buttonText="Log in with Google"
                    /> 
                    <div className="flex flex-row items-center gap-4">
                        <p>
                            New here? 
                        </p>
                        <Button variant="ghost" className="border-2" onClick={() => navigate('/register')}>Sign Up</Button>
                    </div>
                </div>
            </div>
            <ToastContainer position="bottom-right" hideProgressBar/>
        </GoogleOAuthProvider>
    );
};

export default Login;
