import logo from '../icon.svg';
import { useNavigate } from 'react-router-dom';
import { auth, get_auth_headers, clearLocalToken} from '../api';
import { Button } from '../components/ui/button';

export default function PageHeader() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-row bg-banner w-full justify-between">
            <div className="flex flex-row justify-start items-center font-bold">
                <img className="h-8 m-2" src={logo} alt="Logo" />
                <h1 className="text-secondary-foreground cursor-pointer" onClick={() => {navigate('/')}}>JobHawk</h1>
            </div>
        </div>
    )
}

export function PageHeaderWithLogout() {
    const navigate = useNavigate();
    function handleLogout(event) {
        event.preventDefault();
        auth.post('/logout/', {}, {headers: get_auth_headers()}).finally(() => {
            clearLocalToken();
            navigate('/')
        })
    }
    return (
        <div className="flex flex-row bg-banner w-full justify-between">
            <div className="flex flex-row justify-start items-center font-bold">
                <img className="h-8 m-2" src={logo} alt="Logo" />
                <h1 className="text-secondary-foreground cursor-pointer" onClick={() => {navigate('/')}}>JobHawk</h1>
            </div>
            <Button variant="secondary" size="sm" className="m-2" onClick={handleLogout}>Logout</Button>
        </div>
    )
}