import * as React from "react"
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table"

import { ChevronDown, ArrowUpDown } from "lucide-react"

 
import { Button } from "../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"

import { Checkbox } from "../components/ui/checkbox"

import { patchApplicationStatus, handleRowsChanged } from './grid/RowChangeHandler';
import { deleteJobPostings } from './grid/RowDeleteHandler';
import JobDetailModal from "./JobDetailModal"

const APPLICATION_STATUS_CHOICES = [
    "Not Started",
    "Applying",
    "Applied",
    "Interviewing",
    "Offered",
    "Rejected"
]

function DataTable({
  columns,
  data,
  rowSelection,
  setRowSelection,
  setClickedRow,
  isLoadingRows,
}) {
  const [sorting, setSorting] = React.useState([])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      rowSelection,
    },
  })

  return (
    <div className="rounded-md border max-h-[65vh] overflow-y-auto">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
                <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className="hover:bg-slate-100 hover:cursor-pointer"
                    onClick={() => setClickedRow(row.original) }
                >
                    {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                    ))}
                </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {isLoadingRows ? "Loading jobs..." : "Add some job postings to get started!"}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

const statusDropdown = (row, rows, setRows) => {
    const status = row.getValue("application_status")
    const handleStatusChange = async (newStatus) => {
        if (patchApplicationStatus(row.original, newStatus)) {
            setRows(rows.map((r) => {
                if (r.id === row.original.id) {
                    return { ...r, application_status: newStatus }
                }
                return r
            }))
        }
    }

    const choices = APPLICATION_STATUS_CHOICES.map((choice) => 
        <DropdownMenuItem key={choice} onClick={event => {event.stopPropagation(); handleStatusChange(choice)}}>{choice}</DropdownMenuItem>
    )

    return (
        <DropdownMenu>
        <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="p-2 pl-4">
                {status}
            <span className="sr-only">Open menu</span>
            <ChevronDown className="h-4 w-4" />
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="bg-white">
            <DropdownMenuLabel>Set Status</DropdownMenuLabel>
            {choices}
        </DropdownMenuContent>
        </DropdownMenu>
    )
}

const sortableHeader = (headerTitle) => {
    return ({ column }) => {
        return (
            <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
                {headerTitle}
            <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
        )
    }
}

const getNestedDataSortingFn = (accessPath) => {
    return (rowA, rowB, columnId) => {
        let accessors = accessPath.split(".");
        let a = rowA.original;
        let b = rowB.original;
        for (let i = 0; i < accessors.length; i++) {
            a = a[accessors[i]];
            b = b[accessors[i]];
        }
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }
  }

const getColumns = (rows, setRows) => {
    return [
        {
            id: "select",
            header: ({ table }) => (
              <Checkbox
                checked={
                  table.getIsAllPageRowsSelected() ||
                  (table.getIsSomePageRowsSelected() && "indeterminate")
                }
                onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                aria-label="Select all"
              />
            ),
            cell: ({ row }) => (
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                onClick={event => event.stopPropagation()}
                aria-label="Select row"
              />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        { 
            accessorKey: 'job_title', 
            header: sortableHeader("Job Title"),
            sortingFn: getNestedDataSortingFn("job_details.job_title"),
            cell: ({ row }) => { 
                const title = row.original.job_details.job_title
                const placeholder = <span className="italic">Fill in Job Details</span>
                return <span className="pl-4 text-nowrap font-medium text-blue-600 dark:text-blue-500 hover:underline">
                    <a href={row.original.url} target="_blank" onClick={event => event.stopPropagation()}>
                        {title ? title : placeholder}
                    </a>
                </span> 
            }
        },
        { 
            accessorKey: 'company', 
            header: sortableHeader("Company"),
            sortingFn: getNestedDataSortingFn("job_details.company"),
            cell: ({ row }) => { return <span className="pl-4">{row.original.job_details.company}</span> },
        },
        { 
            accessorKey: 'location', 
            header: sortableHeader("Location"),
            sortingFn: getNestedDataSortingFn("job_details.location"),
            cell: ({ row }) => { return <span className="pl-4">{row.original.job_details.location}</span> }
        },
        { 
            accessorKey: 'application_status', 
            header: sortableHeader("Status"),
            cell: ({ row }) => { return statusDropdown(row, rows, setRows) }
        },
        {   
            accessorKey: 'created_at', 
            header: sortableHeader("Date Added"),
            cell: ({ row }) => {
                const date = new Date(row.original.created_at);
                const formattedDate = date.toLocaleDateString();
                return <span className="pl-4">{`${formattedDate}`}</span>;
            }
        },
    ]
}

function SelectedRowAction ({ rows, setRows, rowSelection, setRowSelection }) {
    const selectedRows = Object.keys(rowSelection).map((selectedRowIdx) => rows[selectedRowIdx]);
    const selectedLength = selectedRows.length;

    const handleDelete = async () => {
        const selectedRowJobIds = selectedRows.map((row) => row.id);
        if (deleteJobPostings(selectedRowJobIds)) {
            setRows(rows.filter((r) => !selectedRows.includes(r)));
            setRowSelection({});
        }
    }

    return (
        <div className="flex flex-row w-full justify-between mt-4 items-center px-4 py-2 fixed bottom-0 inset-x-0 bg-white border">
            <div>
                Selected {selectedLength} {selectedLength > 1 ? "jobs" : "job"}
            </div>
            <Button onClick={handleDelete} variant="destructive">Delete</Button>
        </div>
    )
}

function JobGrid(props) {
    const { rows, setRows, refreshRows, isLoadingRows } = props;
    const columns = getColumns(rows, setRows);
    const [rowSelection, setRowSelection] = React.useState({})
    const [clickedRow, setClickedRow] = React.useState(undefined);
    
    // When rows change, if there was a clickedRow, ensure that it is updated
    // with the potentially new row data.
    React.useEffect(() => {
        if (clickedRow) {
            const updatedRow = rows.find((r) => r.id === clickedRow.id);
            setClickedRow(updatedRow);
        }
    }, [rows]);

    const selectedRowAction = Object.keys(rowSelection).length !== 0 ? (<SelectedRowAction 
        rows={rows}
        setRows={setRows}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
    />) : <></>

    return (
        <div className="container mx-auto py-10 flex flex-col pb-24">
            <DataTable 
                columns={columns} 
                data={rows} 
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                setClickedRow={setClickedRow}
                isLoadingRows={isLoadingRows}
            />
            {selectedRowAction} 
            <JobDetailModal clickedRow={clickedRow} setClickedRow={setClickedRow} refreshRows={refreshRows}/>
        </div>
    );
}

export default JobGrid;