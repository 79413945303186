import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { authenticated_api } from '../api';
import { Button } from '../components/ui/button';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "../components/ui/form"
import { Input } from "../components/ui/input"
import { Spinner } from '../components/ui/spinner';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
  } from "../components/ui/dropdown-menu"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

const countryList = [
    "Remote",
    "United States",
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands",
	"Colombia",
	"Comoros",
	"Congo (the Democratic Republic of the)",
	"Congo",
	"Cook Islands",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands [Malvinas]",
	"Faroe Islands",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom of Great Britain and Northern Ireland",
	"United States Minor Outlying Islands",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

const industries = [  
    "Computer Software",
    "Computer Hardware",
    "Information Technology & Services",
    "Design",
    "Hospital & Health Care",
    "Construction",
    "Education Management",
    "Retail",
    "Financial Services",
    "Telecommunications",
    "Higher Education",
    "Oil & Energy",
    "Automotive",
    "Accounting",
    "Real Estate",
    "Marketing and Advertising",
    "Banking",
    "Management Consulting",
    "Insurance",
    "Government Administration",  
    "Logistics and Supply Chain",
    "Consumer Electronics",
    "Legal Services",
    "Apparel & Fashion",
    "Airlines",
    "Aviation & Aerospace",
    "Research",
    "Machinery",
    "Hospitality",
    "Pharmaceuticals",
    "Environmental Services",
    "Nonprofit Organization Management",
    "Electrical/Electronic Manufacturing",
    "Military",
    "Consumer Goods",
    "Publishing",
    "Packaging and Containers",
    "Motion Pictures and Film",
    "Broadcast Media",
    "Entertainment",
    "Health, Wellness and Fitness",
    "Other", // TODO
    ];


const employmentTypes = ["Full Time Role", "Internship", "Part Time Role", "Contract Role"]

const formSchema = z.object({
    first_name: z.string().min(1, { message: "" }),
    last_name: z.string().min(1, { message: "" }),
    yoe: z.number().int().nonnegative(),
	industry: z.string().min(1),
	country: z.string().min(1),
	employment_type: z.string().min(1),
	linkedin_url: z.string().min(1).optional(),
	desired_salary: z.number().int().nonnegative().optional(),
})

export function NUXForm({ setShowNux }) {
    const [isSaving, setIsSaving] = useState(false);
    const form = useForm({
      resolver: zodResolver(formSchema),
      defaultValues: {
		first_name: "",
		last_name: "",
		yoe: 0,
	  },
    })
   
    function onSubmit(values) {
	  const postData = Object.fromEntries(
		Object.entries(values).filter(([_, value]) => value !== undefined)
	  );
      setIsSaving(true);

	  authenticated_api().post('/user_details/', postData).then(res => {
		if (res.status === 201) {
			console.log('success', res.data)
			toast.success('Welcome to JobHawk, ' + postData["first_name"]);
			setShowNux(false);
		} else {
			toast.error('Something went wrong');
			console.log(res.data);
		}
		}).catch(err => {
			toast.error('Something went wrong');
			console.log(err);
		}).finally(() => {
			setIsSaving(false);
		});
    }
    return (
		<div className="flex flex-col border rounded-md max-w-lg mx-auto mt-16 p-8 gap-4">
			<h1 className="text-lg">Welcome to JobHawk!</h1>
			<h2 className="text-md">Tell us a little bit about yourself</h2>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
				<FormField
					control={form.control}
					name="first_name"
					render={({ field }) => (
						<FormItem>
							<FormLabel>First Name <span className="text-red-500">*</span></FormLabel>
							<FormControl>
								<Input placeholder="First Name" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="last_name"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Last Name <span className="text-red-500">*</span></FormLabel>
							<FormControl>
								<Input placeholder="Last Name" {...field}/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="yoe"
					render={({ field }) => (
						<FormItem>
						<FormLabel>Years of Experience <span className="text-red-500">*</span></FormLabel>
						<FormControl>
							<Input type="number" placeholder="Years of Experience" {...field} onChange={(e) => field.onChange(Number(e.target.value))}/>
						</FormControl>
						<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
				control={form.control}
				name="industry"
				render={({ field }) => (
					<FormItem className="flex flex-col">
						<FormLabel>Industry <span className="text-red-500">*</span></FormLabel>
						<FormControl>
							<DropdownMenu>
							<DropdownMenuTrigger>
								<Input placeholder="Industry" className="text-left hover:cursor-pointer" {...field}/>
							</DropdownMenuTrigger>
							<DropdownMenuContent className="bg-white max-h-64 overflow-y-scroll">
								{industries.map((industry) => (
								<DropdownMenuItem
									key={industry}
									onClick={() => field.onChange(industry)}
								>
									{industry}
								</DropdownMenuItem>
								))}
							</DropdownMenuContent>
							</DropdownMenu>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
				/>
				<FormField
				control={form.control}
				name="employment_type"
				render={({ field }) => (
					<FormItem className="flex flex-col">
					<FormLabel>Employment Type <span className="text-red-500">*</span></FormLabel>
					<FormControl>
						<DropdownMenu>
						<DropdownMenuTrigger>
							<Input placeholder="I'm looking for a" className="text-left hover:cursor-pointer" {...field}/>
						</DropdownMenuTrigger>
						<DropdownMenuContent className="bg-white max-h-64 overflow-y-scroll">
							{employmentTypes.map((type) => (
							<DropdownMenuItem
								key={type}
								onClick={() => field.onChange(type)}
							>
								{type}
							</DropdownMenuItem>
							))}
						</DropdownMenuContent>
						</DropdownMenu>
					</FormControl>
					<FormMessage />
					</FormItem>
				)}
				/>
				<FormField
				control={form.control}
				name="country"
				render={({ field }) => (
					<FormItem className="flex flex-col">
					<FormLabel>Country<span className="text-red-500">*</span></FormLabel>
					<FormControl>
						<DropdownMenu>
						<DropdownMenuTrigger>
							<Input placeholder="I'm applying to jobs in" className="text-left hover:cursor-pointer" {...field}/>
						</DropdownMenuTrigger>
						<DropdownMenuContent className="bg-white max-h-64 overflow-y-scroll">
							{countryList.map((type) => (
							<DropdownMenuItem
								key={type}
								onClick={() => field.onChange(type)}
							>
								{type}
							</DropdownMenuItem>
							))}
						</DropdownMenuContent>
						</DropdownMenu>
					</FormControl>
					<FormMessage />
					</FormItem>
				)}
				/>
				<FormField
				control={form.control}
				name="linkedin_url"
				render={({ field }) => (
					<FormItem>
					<FormLabel>LinkedIn URL</FormLabel>
					<FormControl>
						<Input type="url" placeholder="LinkedIn URL" {...field} />
					</FormControl>
					<FormMessage />
					</FormItem>
				)}
				/>

				<FormField
				control={form.control}
				name="desired_salary"
				render={({ field }) => (
					<FormItem>
					<FormLabel>Desired Salary</FormLabel>
					<FormControl>
						<Input type="number" placeholder="Desired Salary" {...field} onChange={(e) => field.onChange(Number(e.target.value))}/>
					</FormControl>
					<FormMessage />
					</FormItem>
				)}
				/>
				{isSaving ? <Button variant="secondary" disabled><Spinner/></Button> : <Button variant="secondary" type="submit">Save</Button>}
				</form>
			</Form>
		</div>
    )
  }
