import { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { authenticated_api } from '../api';
import { toast } from 'react-toastify';

import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
  } from "../components/ui/drawer"
import { Button } from '../components/ui/button';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "../components/ui/form"
  import { Input } from "../components/ui/input"

import { Pencil } from 'lucide-react';

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { patchChangedValue } from './grid/RowChangeHandler';
import { Spinner } from '../components/ui/spinner';
 
const formSchema = z.object({
    job_title: z.string().min(1),
    company: z.string().min(1),
    location: z.string().min(1),
    url: z.string().min(1),
})

function EditJobDetailsForm({rowData, setIsEditing, refreshRows}) {
    const [isSaving, setIsSaving] = useState(false);
    const form = useForm({
      resolver: zodResolver(formSchema),
      defaultValues: {
        job_title: rowData?.job_details.job_title || "", 
        company: rowData?.job_details.company || "", 
        location: rowData?.job_details.location || "", 
        url: rowData?.url || "", 
      },
    })
   
    function onSubmit(values) {
      console.log(values)
      setIsSaving(true);
      const patchValues = {
        "url": values.url,
        "job_details": {
            "job_title": values.job_title,
            "company": values.company,
            "location": values.location,
        }
      }
      patchChangedValue('job_posting', rowData?.job_details.id, patchValues).then(() => {
        toast.success('Saved');
        setIsSaving(false);
        setIsEditing(false);
        // Note: refreshing rows will trigger an update to clickedRow with the
        // updated row values. This takes place in the parent grid.
        refreshRows();
      });
    }
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
                control={form.control}
                name="job_title"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>Job Title</FormLabel>
                        <FormControl>
                            <Input placeholder="Job Title" {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="company"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>Company</FormLabel>
                        <FormControl>
                            <Input placeholder="Company" {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="location"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>Job Location</FormLabel>
                        <FormControl>
                            <Input placeholder="Job Location" {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="url"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>Job Posting</FormLabel>
                        <FormControl>
                            <Input placeholder="Job Posting URL" {...field} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            {isSaving ? <Button variant="secondary" disabled><Spinner/></Button> : <Button variant="secondary" type="submit">Save</Button>}
            </form>
        </Form>
    )
  }
  
function saveNotes(value, clickedRow) {
    const user_job_details_id = clickedRow.user_job_details;
    return authenticated_api().patch(`/user_job_details/${user_job_details_id}/`, {
        "notes": value
    }).then(
        response => {
            const success = response.status === 200
            if (success) {
                toast.success('Saved')
            } else {
                toast.error('Save failed')
                console.log(response.data)
            }
            return success;
        }
    ).catch(
        error => {
            toast.error('Save failed')
            console.log(error)
            return false;
        }
    );
}

function DisplayJobDetails({rowData}) {
    if (!rowData) {
        return <></>
    }

    const hasJobDetails = rowData.job_details.job_title !== '';
    const jobTitle = rowData.job_details.job_title
    const company = rowData.job_details.company
    const jobLocation = rowData.job_details.location
    const status = rowData.application_status
    const filledJobDetails = (
        <div className="flex flex-col">
            <a href={rowData.url} target="_blank" className="text-blue-600 dark:text-blue-500 hover:underline">
                <span className="font-bold">
                    {jobTitle + " "}
                </span>
                <span>
                    @ {company}
                </span>            
            </a>
            <span className="italic">
                {jobLocation}
            </span>
            <span className="mt-4">
                Status: {status}
            </span>
        </div>
    )
    const display = hasJobDetails ? filledJobDetails : <></>;

    return (
        <>
            {display}
        </>
    )
}


function JobDetailModal(props) {
    const { clickedRow, setClickedRow, refreshRows } = props;
    const [isEditing, setIsEditing] = useState(false);

    const reset = () => {
        setClickedRow(undefined);
    }

    useEffect(() => {
        setIsEditing(clickedRow?.job_details.job_title === '');
    }, [clickedRow]);

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            reset();
        }
    }

    const displayJobDetails = <DisplayJobDetails rowData={clickedRow}/>
    const editJobDetails = <EditJobDetailsForm rowData={clickedRow} setIsEditing={setIsEditing} refreshRows={refreshRows}/> 

    const display = isEditing ? 
        editJobDetails : 
        <div>
            {displayJobDetails}
        </div>;

    const editButton = isEditing ? <></> :
        <Button variant="outline" onClick={() => {setIsEditing(true)}}>Edit Job Details</Button>
    
    // useEffect(() => {
    //     if(clickedRow) {
    //         const user_job_details_id = clickedRow.user_job_details;
    //         authenticated_api().get(`/user_job_details/${user_job_details_id}`).then(response => {
    //             setValue(response.data.notes);
    //         }).catch(error => {
    //             toast.error('Failed to load Job Details')
    //             console.log(error);
    //         });
    //     } else if(value) {
    //         // when clickedRow is set back to undefined
    //         // and value is defined, clear it
    //         setValue('')
    //     }
    // }, [clickedRow]);
    useEffect(() => {
        // add event listener on mount
        document.addEventListener('keydown', handleKeyDown);
        // remove event listener on unmount
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);


    return (
        <div>
            <Drawer open={clickedRow !== undefined} dismissible={false}>
                <DrawerContent className="max-w-xl mx-auto px-8">
                    <DrawerHeader>
                        {display}
                    </DrawerHeader>
                    <DrawerFooter>
                        {editButton}
                        <Button variant="outline" onClick={() => {reset()}}>Cancel</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </div>
    )   
}

export default JobDetailModal;