import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../icon.svg';
import grid from '../grid.png';
import notes from '../notes.png';
import addjob from '../addjob.gif';
import { Button } from '../components/ui/button';

function NavBar() {
    return (
        <nav className="p-8 fixed bg-banner border-b border-secondary w-full">
            <div className="flex flex-row justify-between items-center text-secondary-foreground">
                <div className="flex flex-row justify-start items-center font-bold gap-2">
                    <img className="h-8 m-2" src={logo} alt="Logo" />
                    <h3 className="cursor-pointer text-2xl"><a href="#">JobHawk</a></h3>
                </div>
                <ul className="hidden md:flex flex-row gap-8 text-lg">
                    <li><a href="#overview">Overview</a></li>
                    <li><a href="#testimonials">Testimonials</a></li>
                    <li><a href="#faq">FAQs</a></li>
                </ul>
                <div className="flex flex-row gap-4">
                    <Button variant="secondary" size="lg" className="hidden md:flex">
                        <Link to="/login">
                            <span className="text-lg">Log In</span>
                        </Link>
                    </Button>
                    <Button variant="secondary" size="lg">
                        <Link to="/register">
                            <span className="text-lg">Sign Up</span>
                        </Link>
                    </Button>
                </div>
            </div>
        </nav>
    );
}

function Header() {
    return (
        <div className="pt-40 mx-auto text-center">
            <div className="text-secondary-foreground bg-banner flex flex-col items-center gap-8">
                <h1 className="text-6xl md:text-9xl font-bold"><span className="text-secondary">Streamline</span> Your Job Hunt</h1>
                <h2 className="text-3xl md:text-6xl font-semibold">The Ultimate Job Application Tracker</h2>
                <div className="flex flex-col gap-2 md:gap-4 text-md md:text-3xl mx-2">
                    <p>Ditch the excel spreadsheet or your notes app</p>
                    <p>Never lose track of a job application again</p>
                    <p>Organize your job hunt and focus on landing the job</p>
                </div>
                <Button variant="secondary" className="h-16 rounded-md px-12 my-4 w-min">
                    <Link to="/register">
                        <span className="text-lg md:text-2xl">
                            Start Job Tracking Today!
                        </span>
                    </Link>
                </Button>
            </div>
        </div>
    );
}

function Img({src, alt}) {
    return (
        <>
            <img className="w-9/12 mt-4 rounded" src={src} alt={alt} /> 
        </>
    )
}

function Main() {
    return (
            <div className="flex flex-col items-center mt-24 text-secondary-foreground gap-24 w-full mx-auto text-center">
                <div className="flex flex-col items-center gap-24" id="overview">
                    <div className="flex flex-col items-center gap-4 mx-8">
                        <h2 className="text-xl md:text-4xl font-semibold">🎯 All Your Job Applications in One Place</h2>
                        <div className="text-md md:text-xl flex flex-col items-center gap-2">
                            <p>Easily track every job application from submission to offer.</p>
                            <p>JobHawk's intuitive dashboard keeps all your job opportunities within reach.</p>
                        </div>
                        <Img src={grid} alt="Grid" />
                    </div>
                    <div className="flex flex-col items-center gap-4 mx-8">
                        <h2 className="text-xl md:text-4xl font-semibold">📝 Keep All Your Job Hunt Notes Organized</h2>
                        <div className="text-md md:text-xl flex flex-col items-center gap-2">
                            <p>Centralize your job hunt notes in one place.</p>
                            <p>Interview prep, company research, recruiter call notes...</p>
                            <p>Save the headache and manage your notes in JobHawk.</p>
                        </div>
                        <Img src={notes} alt="Notes" />
                    </div>
                    <div className="flex flex-col items-center gap-4 mx-8">
                        <h2 className="text-xl md:text-4xl font-semibold">🪄 Fill in job details <span className='text-secondary'>Automagically</span></h2>
                        <div className="text-md md:text-xl flex flex-col items-center gap-2">
                            <p>Let JobHawk do the heavy lifting for you.</p>
                            <p>Automatically fill in job details from job postings to save time and effort.</p>
                        </div>
                        <Img src={addjob} alt="Add job" />
                    </div>
                    <div className="flex flex-col items-center gap-4 mx-8">
                        <h2 className="text-xl md:text-4xl font-semibold">📈 Start Tracking Your Job Search Effortlessly</h2>
                        <div className="text-md md:text-xl flex flex-col items-center gap-2">
                            <p>Take control of your job hunt with powerful tools designed to streamline your search process.</p>
                            <p>From tracking applications to organizing interviews, JobHawk is your all-in-one job search management tool!</p>
                        </div>
                        <Button variant="secondary" className="h-12 rounded-md px-10 my-4 w-min">
                            <Link to="/register">
                                <span className="text-lg md:text-xl">
                                    Sign Up for Free Today!
                                </span>
                            </Link>
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-4 mx-8" id="testimonials">
                    <h2 className="text-lg md:text-2xl font-semibold">❤️ What Our Users Say</h2>
                    <blockquote className="text-md">
                        <p>"JobHawk has transformed my job search process. It's like having a personal assistant for my job hunt!"</p>
                        <p>"With JobHawk, I never miss a deadline or lose track of where I am in the application process!"</p>
                        <cite>A happy JobHawk user</cite>
                    </blockquote>
                    <h2 className="text-lg md:text-2xl font-semibold mt-4">🙋‍♀️ Join Thousands of Satisfied Users</h2>
                    <p>Don't just take our word for it. Join the growing community of job seekers</p>
                    <p>who have made JobHawk their go-to tool for managing their job search.</p>
                    <Button variant="secondary" className="h-12 rounded-md px-10 my-4 w-min">
                        <Link to="/register">
                            <span className="text-lg md:text-xl">
                                Sign Up for Free Today!
                            </span>
                        </Link>
                    </Button>
                </div>
                <div className="flex flex-col items-center gap-4 mx-8" id="faq">
                    <h2 className="text-lg md:text-2xl font-semibold">🤔 Frequently Asked Questions</h2>
                    <h3 className="text-md md:text-xl">How does JobHawk help with my job search?</h3>
                    <p>JobHawk helps you keep track of all your job applications, interviews, and notes in one easy-to-use platform</p>
                    <h3 className="text-md md:text-xl">Is my information secure?</h3>
                    <p>Yes, JobHawk prioritizes your privacy and security. Your data is encrypted and stored securely.</p>
                    <h3 className="text-md md:text-xl">Is JobHawk free?</h3>
                    <p>100% Free! Sign up today and start using JobHawk's powerful features to streamline your job search.</p>
                </div>
                <div className="flex flex-col items-center gap-4 mx-8">
                    <h2 className="text-lg md:text-2xl font-semibold">Ready to Take Your Job Hunt to the Next Level?</h2>
                    <p>Sign up with JobHawk and streamline your job search today.</p>
                    <p>Let us help you organize your job applications, so you can focus on what really matters - landing your next job!</p>
                    <Button variant="secondary" className="h-12 rounded-md px-10 my-4 w-min">
                        <Link to="/register">
                            <span className="text-lg md:text-xl">
                                Sign Up Now!
                            </span>
                        </Link>
                    </Button>
                </div>
            </div>
    );
}

function Footer() {
    return (
        <div className="bg-banner text-secondary-foreground py-4 mt-8">
            <div className="text-center">
                <p>&copy; 2025 JobHawk</p>
            </div>
        </div>
    );
}

function LandingPage() {
    return (
        // TODO: font family
        <div className="bg-banner font-sans">
            <NavBar />
            <Header />
            <Main />
            <Footer />
        </div>
    );
}

export default LandingPage;
