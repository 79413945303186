import React, { useState, useEffect } from 'react';
import { authenticated_api } from '../api';
import { Link } from 'react-router-dom';
import JobPostingForm from './JobPostingForm';
import JobGrid from '../job_tracker/JobGrid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PageHeaderWithLogout } from './PageHeader';
import { NUXForm} from '../auth/Nux';

function JobTracker() {
  const [jobs, setJobs] = useState([]);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const [formCount, setFormCount] = useState(0);
  const [showNux, setShowNux] = useState(false);

  const refreshJobPostings = () => {
    setIsLoadingJobs(true);
    authenticated_api().get('/job_posting/').then(response => {
      setJobs(response.data);
    }).catch(error => {
      toast.error('Failed to load Job Postings');
      console.log(error);
    }).finally(() => {
      setIsLoadingJobs(false);
    });
  }

  useEffect(() => {
    refreshJobPostings();
    // formCount is a dependency so that the form submit can
    // trigger an API refresh
  }, [formCount]);

  useEffect(() => {
    authenticated_api().get('/should_show_nux/').then(response => {
      setShowNux(response.data.show_nux);
    })
  }, []);

  return (
    <>
      <PageHeaderWithLogout/>
      {
        showNux ? <NUXForm setShowNux={setShowNux}/> : 
        <div className="flex flex-col mt-8">
          <JobPostingForm 
            count={formCount}
            setCount={setFormCount}
          />
          <JobGrid rows={jobs} setRows={setJobs} refreshRows={refreshJobPostings} isLoadingRows={isLoadingJobs}/>
          {/* Configure with https://fkhadra.github.io/react-toastify/introduction/ */}
        </div>
      }
      <ToastContainer position="bottom-right" hideProgressBar/>
    </>

  );
}

export default JobTracker;